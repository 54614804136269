import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hash Link Routing for React Applications`}</h1>
    <p>{`When working on a client web application earlier this year I needed to support a common navigation implementation. The users would need to be able to navigate directly to an element on a page using hash link routing.`}</p>
    <p>{`My team and I were leveraging react router to handle our page navigation. After doing some research I reached for the most popular npm package I could find, `}<inlineCode parentName="p">{`react-router-hash-link`}</inlineCode>{`.`}</p>
    <p>{`This package surfaces some components to be used as an alternative to `}<inlineCode parentName="p">{`react-router-dom`}</inlineCode>{` components such as `}<inlineCode parentName="p">{`Link`}</inlineCode>{` and `}<inlineCode parentName="p">{`NavLink`}</inlineCode>{`. The replacement components allow the user to navigate directly to elements on the page if the path specified in the value of the `}<inlineCode parentName="p">{`to`}</inlineCode>{` property includes a hash fragment and the target element has in `}<inlineCode parentName="p">{`id`}</inlineCode>{` identical to the hash fragment value. There is a stipulation that you must be using react router's `}<inlineCode parentName="p">{`BrowserRouter`}</inlineCode>{` to take advantage of this package which was fine with us because that's exactly what we were using. `}</p>
    <p>{`However, for our use case, I quickly found that the flagship scroll-to-element functionality wasn't working as needed in the following circumstances:`}</p>
    <ul>
      <li parentName="ul">{`Browser forward/back operation to a URL with a hash fragment`}</li>
      <li parentName="ul">{`Opening a URL with a hash fragment in a new tab`}</li>
      <li parentName="ul">{`Page refresh`}</li>
    </ul>
    <p>{`To my surprise this seemingly common problem was disappointingly not fully solved. Don't get me wrong, `}<inlineCode parentName="p">{`react-router-hash-link`}</inlineCode>{` is a completely valid solution for many use cases. Unfortunately, there were too many unsupported features for our use case.`}</p>
    <p>{`At this point I had taken a look at a few popular packages in hopes that one would "just work". However, I ended up having to hand-roll the solution to fit our customer's needs. Using inspiration from `}<inlineCode parentName="p">{`react-router-hash-link`}</inlineCode>{` and `}<a parentName="p" {...{
        "href": "https://medium.com/@gajus/making-the-anchor-links-work-in-spa-applications-618ba2c6954a"
      }}>{`this blog post`}</a>{` I created `}<a parentName="p" {...{
        "href": "https://github.com/sean-beard/react-hash-link"
      }}><inlineCode parentName="a">{`react-hash-link`}</inlineCode></a>{`.`}</p>
    <p><inlineCode parentName="p">{`react-hash-link`}</inlineCode>{` works in all of the following situations from the README.`}</p>
    <ul>
      <li parentName="ul">{`Navigating to a URL with a hash fragment and corresponding element on the page`}</li>
      <li parentName="ul">{`Opening qualifying URLs/pages in a new browser tab or window`}</li>
      <li parentName="ul">{`Forward browser navigation`}</li>
      <li parentName="ul">{`Backward browser navigation`}</li>
      <li parentName="ul">{`Page reload`}</li>
      <li parentName="ul">{`Works with `}<inlineCode parentName="li">{`react-router`}</inlineCode>{` but not dependent on it`}</li>
      <li parentName="ul">{`Works with server-side rendering`}</li>
      <li parentName="ul">{`All of the above scenarios function correctly when used across all major browsers (including IE)`}</li>
    </ul>
    <p>{`Check out the differences between these packages by comparing a `}<a parentName="p" {...{
        "href": "https://f1lme.csb.app"
      }}>{`sample app using `}<inlineCode parentName="a">{`react-router-hash-link`}</inlineCode></a>{` and the `}<a parentName="p" {...{
        "href": "https://11rzv.csb.app"
      }}>{`same app using `}<inlineCode parentName="a">{`react-hash-link`}</inlineCode></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      